export const userStatuses = {
    active: { statusCode: 1, title: 'Active', className: 'active' },
    blocked: { statusCode: 2, title: 'Blocked', className: 'blocked' },
    invited: { statusCode: 3, title: 'Invited', className: 'invited' },
    notInivited: { statusCode: 4, title: 'Not Invited', className: 'not-invited' },
    toArray: function () {
        return [this.active, this.blocked, this.invited, this.notInivited];
    },
    getByStatusCode: function (statusCode) {

        return this.toArray().find(status => status.statusCode === statusCode);
    }
}
