import {
    compareRoles,
    compareRating,
    compareStrings,
    compareCountries,
    compareDateHours,
    compareDateAndMonthStamp,
    compareNumbersWithNullDesc,
    compareNumbersWithNullAcs,
    compareNestedStrings,
    compareRoleShortTitles,
    compareDatesAsc,
    compareDatesDesc,
    compareSettlementStatus,
    compareBidAsDealerAgreementStatus,
    compareSettlementAccounts,
    compareBoolean,
    compareBooleanAndNull,
    compareTransactionStatuses,
} from "../utils/compare.utils";

export const SORT = {
    ASC: 'asc',
    DESC: 'desc'
};

export const SORTING_TYPE = {
    rolesShortTitles: 'rolesShortTitles',
    roles: 'roles',
    rating: 'rating',
    string: 'string',
    number: 'number',
    date: 'date',
    dateHours: 'dateHours',
    country: 'country',
    dateYearAndMonth: 'dateYearAndMonth', // sort value 'MM/YY'
    transactionCurrency: 'transactionCurrency',
    company: 'company',
    settlementStatus: 'settlementStatus',
    settlementRequestStatus: 'settlementRequestStatus',
    settlementAccounts: 'settlementAccounts',
    boolean: 'boolean',
    booleanAndNull: 'booleanAndNull',
    transactionStatus: 'transactionStatus',
};

export const sortingOptions = [
    {
        type: SORTING_TYPE.rolesShortTitles,
        direction: SORT.ASC,
        compare: (a, b) => compareRoleShortTitles(a, b),
    },
    {
        type: SORTING_TYPE.rolesShortTitles,
        direction: SORT.DESC,
        compare: (a, b) => compareRoleShortTitles(b, a),
    },
    {
        type: SORTING_TYPE.roles,
        direction: SORT.ASC,
        compare: (a, b) => compareRoles(a, b),
    },
    {
        type: SORTING_TYPE.roles,
        direction: SORT.DESC,
        compare: (a, b) => compareRoles(b, a),
    },
    {
        type: SORTING_TYPE.string,
        direction: SORT.ASC,
        compare: (a, b) => compareStrings(a, b),
    },
    {
        type: SORTING_TYPE.string,
        direction: SORT.DESC,
        compare: (a, b) => compareStrings(b, a),
    },
    {
        type: SORTING_TYPE.rating,
        direction: SORT.ASC,
        compare: (a, b) => compareRating(a, b),
    },
    {
        type: SORTING_TYPE.rating,
        direction: SORT.DESC,
        compare: (a, b) => compareRating(b, a),
    },
    {
        type: SORTING_TYPE.number,
        direction: SORT.ASC,
        compare: (a, b) => compareNumbersWithNullAcs(a, b),
    },
    {
        type: SORTING_TYPE.number,
        direction: SORT.DESC,
        compare: (a, b) => compareNumbersWithNullDesc(a, b),
    },
    {
        type: SORTING_TYPE.boolean,
        direction: SORT.ASC,
        compare: (a, b) => compareBoolean(a, b),
    },
    {
        type: SORTING_TYPE.boolean,
        direction: SORT.DESC,
        compare: (a, b) => compareBoolean(b, a),
    },
    {
        type: SORTING_TYPE.date,
        direction: SORT.ASC,
        compare: (a, b) => compareDatesAsc(a, b),
    },
    {
        type: SORTING_TYPE.date,
        direction: SORT.DESC,
        compare: (a, b) => compareDatesDesc(b, a),
    },
    {
        type: SORTING_TYPE.dateHours,
        direction: SORT.ASC,
        compare: (a, b) => compareDateHours(a, b),
    },
    {
        type: SORTING_TYPE.dateHours,
        direction: SORT.DESC,
        compare: (a, b) => compareDateHours(b, a),
    },
    {
        type: SORTING_TYPE.country,
        direction: SORT.ASC,
        compare: (a, b) => compareCountries(a, b),
    },
    {
        type: SORTING_TYPE.country,
        direction: SORT.DESC,
        compare: (a, b) => compareCountries(b, a),
    },
    {
        type: SORTING_TYPE.dateYearAndMonth,
        direction: SORT.ASC,
        compare: (a, b) => compareDateAndMonthStamp(a, b),
    },
    {
        type: SORTING_TYPE.dateYearAndMonth,
        direction: SORT.DESC,
        compare: (a, b) => compareDateAndMonthStamp(b, a),
    },
    {
        type: SORTING_TYPE.transactionCurrency,
        direction: SORT.ASC,
        compare: (a, b) => compareNestedStrings(a, b, 'code'),
    },
    {
        type: SORTING_TYPE.transactionCurrency,
        direction: SORT.DESC,
        compare: (a, b) => compareNestedStrings(b, a, 'code'),
    },
    {
        type: SORTING_TYPE.company,
        direction: SORT.ASC,
        compare: (a, b) => compareNestedStrings(a, b, 'legalName'),
    },
    {
        type: SORTING_TYPE.company,
        direction: SORT.DESC,
        compare: (a, b) => compareNestedStrings(b, a, 'legalName'),
    },
    {
        type: SORTING_TYPE.settlementStatus,
        direction: SORT.ASC,
        compare: (a, b) => compareSettlementStatus(a, b),

    },
    {
        type: SORTING_TYPE.settlementStatus,
        direction: SORT.DESC,
        compare: (a, b) => compareSettlementStatus(b, a),
    },
    {
        type: SORTING_TYPE.settlementRequestStatus,
        direction: SORT.ASC,
        compare: (a, b) => compareBidAsDealerAgreementStatus(a, b)
    },
    {
        type: SORTING_TYPE.settlementRequestStatus,
        direction: SORT.DESC,
        compare: (a, b) => compareBidAsDealerAgreementStatus(b, a)
    },
    {
        type: SORTING_TYPE.settlementAccounts,
        direction: SORT.ASC,
        compare: (settlementAccountCountA, settlementAccountCountB, agreementA) =>
            compareSettlementAccounts(settlementAccountCountA, settlementAccountCountB, agreementA)
    },
    {
        type: SORTING_TYPE.settlementAccounts,
        direction: SORT.DESC,
        compare: (settlementAccountCountA, settlementAccountCountB, agreementA, agreementB) =>
            compareSettlementAccounts(settlementAccountCountB, settlementAccountCountA, agreementB)
    },
    {
        type: SORTING_TYPE.booleanAndNull,
        direction: SORT.ASC,
        compare: (a, b) => compareBooleanAndNull(a, b),
    },
    {
        type: SORTING_TYPE.booleanAndNull,
        direction: SORT.DESC,
        compare: (a, b) => compareBooleanAndNull(b, a),
    },
    {
        type: SORTING_TYPE.transactionStatus,
        direction: SORT.ASC,
        compare: (a, b) => compareTransactionStatuses(b, a),
    },
    {
        type: SORTING_TYPE.transactionStatus,
        direction: SORT.DESC,
        compare: (a, b) => compareTransactionStatuses(a, b),
    }
];
