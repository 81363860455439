import { UserRequestStatus } from '../types/management/UserRequestStatus';

export const userRequestStatuses = {
    pending: { statusCode: UserRequestStatus.Pending, title: 'Pending Approval', className: 'status-pending-approval' },
    approved: { statusCode: UserRequestStatus.Approved, title: 'Approved', className: 'active' },
    blocked: { statusCode: UserRequestStatus.Blocked, title: 'Blocked', className: 'blocked' },
    toArray: function () {
        return [this.pending, this.approved, this.blocked];
    },
    getByStatusCode: function (statusCode: UserRequestStatus) {
        return this.toArray().find(status => status.statusCode === statusCode);
    }
};
