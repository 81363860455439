export const userProfilePhotoConstants = {
    acceptedFormats: ['image/png', 'image/jpg', 'image/jpeg'],
    maxFileSize: 5242880, //5Mb
    maxFileSizeForIE: 3145728, //3Mb
    sliderValueRange: {
        min: 0,
        max: 5
    },
    sliderStep: 0.1,
    defaultZoom: 0,
    autoCropArea: 1,
    initialAspectRatio: 1,
};
