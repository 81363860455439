import moment from 'moment';

export const estId = 'Eastern Standard Time';

const timeZones = [
    {
        'id': estId,
        'title': 'Eastern Time',
        'abbreviation': 'EST',
        'tz': 'US/Eastern',
    }, {
        'id': 'Central Standard Time',
        'title': 'Central Time',
        'abbreviation': 'CST',
        'tz': 'US/Central',
    }, {
        'id': 'Mountain Standard Time',
        'title': 'Mountain Time',
        'abbreviation': 'MST',
        'tz': 'US/Mountain',
    }, {
        'id': 'Pacific Standard Time',
        'title': 'Pacific Time',
        'abbreviation': 'PST',
        'tz': 'US/Pacific',
    }
];

const offsetSign = (offset: number) => offset > 0 ? '+' : '-'

export const getTimeZones = () => timeZones.map(t => {
    const utcOffsetMinutes = moment.tz(t.tz).utcOffset();
    return {
        ...t,
        utcOffsetMinutes,
        title: `${t.title} (UTC${offsetSign(utcOffsetMinutes)}${Math.abs(utcOffsetMinutes / 60)})`,
    }
});

