import { companyRoles } from "./company-roles";

export const roles = {
    Administrator: 'Administrator',
    SubscriptionManager: 'SubscriptionManager',
    DataEntry: 'DataEntry',
    SellerTrader: 'SellerTrader',
    SellerViewer: 'SellerViewer',
    SellerAdministrator: 'SellerAdministrator',
    BrokerDealerViewer: 'BrokerDealerViewer',
    BrokerDealerTrader: 'BrokerDealerTrader',
    Viewer: 'Viewer',
    ArrangersClient: 'ArrangersClient',
    CollateralManager: 'CollateralManager',
    Media: 'Media',
    seller: function () {
        return [this.SellerTrader, this.SellerViewer];
    },
    bd: function () {
        return [this.BrokerDealerTrader, this.BrokerDealerViewer];
    },
    inventoryAccess: function () {
        return [...roles.seller(), roles.Administrator, roles.DataEntry, roles.Viewer];
    },
    issuanceMonitorAccess: function () {
        return [...this.seller(), ...this.bd(), this.Administrator, this.DataEntry, this.ArrangersClient, this.CollateralManager, this.Media];
    },
    cloManagersAccess: function () {
        return [this.Administrator, this.DataEntry, this.CollateralManager, this.Media, ...this.bd(), ...this.seller()];
    },
    banksAccess: function () {
        return [this.Administrator, this.DataEntry, this.Media, ...this.bd(), ...this.seller()];
    },
    dealsAccess: function () {
        return [this.Administrator, this.DataEntry, this.Media, ...this.bd(), ...this.seller()];
    },
    dealsEdit: function () {
        return [this.Administrator, this.DataEntry];
    },
    salesRepresentativesSupported: function () {
        return [...this.seller(), ...this.bd(), this.Viewer, this.SellerAdministrator];
    },
    subscriptionChange: function () {
        return [...this.seller(), ...this.bd(), this.Viewer, this.SellerAdministrator];
    },
    admin: function () {
        return [
            this.Administrator,
            this.SubscriptionManager
        ];
    },
    getWithout: function(roles, rolesToExclude) {
        return roles.filter(role => !rolesToExclude.includes(role));
    },
    getAllRolesExclude: function (...rolesToExclude) {
        return [
            ...this.admin(),
            ...this.seller(),
            ...this.bd(),
            this.SellerAdministrator,
            this.DataEntry,
            this.ArrangersClient,
            this.Viewer,
            this.CollateralManager,
            this.Media
        ].filter(role => !rolesToExclude.includes(role))
    },
    getByCompanyRole: function (companyRole) {
        switch (companyRole) {
            case companyRoles.Administrator: return [this.Administrator, this.SubscriptionManager, this.DataEntry];
            case companyRoles.Seller: return [this.SellerAdministrator, ...this.seller(), this.ArrangersClient, this.CollateralManager];
            case companyRoles.BrokerDealer: return [this.BrokerDealerViewer, this.BrokerDealerTrader];
            case companyRoles.Viewer: return [this.Viewer];
            case companyRoles.Media: return [this.Media, this.Viewer];
            default: return [];
        }
    },
    getTitle: function (role) {
        switch (role) {
            case this.SellerTrader: return 'Seller Trader';
            case this.SellerViewer: return 'Seller Viewer';
            case this.SellerAdministrator: return 'Seller Admin';
            case this.BrokerDealerViewer: return 'Broker-Dealer Viewer';
            case this.BrokerDealerTrader: return 'Broker-Dealer Trader';
            case this.Administrator: return 'Admin';
            case this.DataEntry: return 'Data Entry';
            case this.SubscriptionManager: return 'Subscription Manager';
            case this.ArrangersClient: return "Arranger's Client";
            case this.CollateralManager: return "Collateral Manager";
            default: return role;
        }
    },
    getShortTitle: function (role) {
        switch (role) {
            case this.SellerTrader: return 'Trader';
            case this.BrokerDealerTrader: return 'Trader';
            case this.SellerViewer: return 'Viewer';
            case this.BrokerDealerViewer: return 'Viewer';
            case this.SellerAdministrator: return 'Admin';
            default: return this.getTitle(role);
        };
    },
    priority: {
        Administrator: 4,
        SubscriptionManager: 3,
        DataEntry: 2,
        SellerTrader: 3,
        SellerViewer: 3,
        SellerAdministrator: 2,
        BrokerDealerViewer: 3,
        BrokerDealerTrader: 3,
        Viewer: 3,
        ArrangersClient: 2,
        CollateralManager: 1,
    },
    sortRolesByPriority(roles) {
        return roles.sort((a, b) => this.priority[b] - this.priority[a]);
    }
}
